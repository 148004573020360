import "../css/users.css";

import React from "react";
import Breadcrumb from "../components/breadcrumb";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import "../css/reports.css"; 

const Reports = () => {  

    let breadcrumbItens = [
        {
            text: "Home",
            route: "/"
        },
        {
            text: "Relatórios",
            route: "#"
        }
    ];

    return (
        <div className="container">
            <div className="defaultContainer">               
                <div className="row">
                    <div className="col-12">
                        <h1 className="h1">Relatórios</h1>
                        <Breadcrumb links={breadcrumbItens} />
                    </div>
                </div>

                <br />
                
                <div className="row">
                    <div className="col-3">
                        <div className="card mb-3 reportCard">
                            <div className="card-header"><span className="material-symbols-outlined">bar_chart_4_bars</span>&nbsp;<label>ROI</label></div>
                            <div className="card-body">
                                <h5 className="card-title">Retorno do inverstimento</h5>
                                <a href="/relatorios/roi" className="btn btn-outline-primary text-uppercase">Ver relatório</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card mb-3 reportCard">
                            <div className="card-header"><span className="material-symbols-outlined">campaign</span>&nbsp;<label>ROI por campanha</label></div>
                            <div className="card-body">
                                <h5 className="card-title">ROI por campanha</h5>
                                <a href="/relatorios/roi-campaign" className="btn btn-outline-primary text-uppercase">Ver relatório</a>
                            </div>
                        </div>
                    </div>    
                    <div className="col-3">
                        <div className="card mb-3 reportCard">
                            <div className="card-header"><span className="material-symbols-outlined">bar_chart_4_bars</span>&nbsp;<label>ROI</label></div>
                            <div className="card-body">
                                <h5 className="card-title">ROI Facebook</h5>
                                <a href="/relatorios/roi-facebook" className="btn btn-outline-primary text-uppercase">Ver relatório</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card mb-3 reportCard">
                            <div className="card-header"><span className="material-symbols-outlined">campaign</span>&nbsp;<label>ROI por campanha</label></div>
                            <div className="card-body">
                                <h5 className="card-title">ROI por campanha Facebook</h5>
                                <a href="/relatorios/roi-campaign-facebook" className="btn btn-outline-primary text-uppercase">Ver relatório</a>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-3">
                        <div className="card mb-3 reportCard">
                            <div className="card-header"><span className="material-symbols-outlined">mail</span>&nbsp;<label>Active Campaign</label></div>
                            <div className="card-body">
                                <h5 className="card-title">Campanhas e Leads</h5>
                                <a href="/relatorios/active" className="btn btn-outline-primary text-uppercase">Ver relatório</a>
                            </div>
                        </div>    
                    </div>  
                </div>

                <br />
                
            </div>
            <HelmetProvider>
                <Helmet>
                    {/* <script src="./js/datatables/gam.js" type="text/javascript"></script> */}
                </Helmet>
            </HelmetProvider>  
        </div>
    );
}

export default Reports;