import {Link} from 'react-router-dom';

import "../css/navbar.css";

import React, {useState, useEffect} from "react";
import MenuButton from "./menuButton";
//import Dropdown from "./dropdown";
import Logo from "../img/logo.png";

const Navbar = () => {

    const [userName, setUserName] = useState('');

    // let dropdownItens = [
    //                         {
    //                             text: "Action",
    //                             route: "#"
    //                         },
    //                         {
    //                             text: "Action 2",
    //                             route: "#"
    //                         },
    //                         {
    //                             text: "Action 3",
    //                             route: "#"
    //                         }
    //                     ];

    const logout = () => {
        var Cookies = document.cookie.split(';');
         for (var i = 0; i < Cookies.length; i++){
            document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
         }
         window.location.href = "/";
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }   
    
    useEffect(() => {
        //checkPermissions();
        let cookie = getCookie('logged');
        //console.log(cookie);
        if(typeof cookie !== "undefined"){
            cookie = JSON.parse(cookie);
            setUserName(cookie.name);

            if(typeof cookie.resources !== "undefined"){
                for (let i = 0; i < cookie.resources.length; i++){
                    let resources = cookie.resources[i];
    
                    switch (resources.table) {
                        case "users":
                            if(!resources.read){
                                window.$("#usuariosBtn").css('display', 'none');
                            }
                        break;
    
                        case "permissions":
                            if(!resources.read){
                                window.$("#permissoesBtn").css('display', 'none');
                            }            
                        break;
    
                        case "verticals":
                            if(!resources.read){
                                window.$("#verticaisBtn").css('display', 'none');
                            }                  
                        break;
    
                        case "key_values":
                            if(!resources.read){
                                window.$("#keyvaluesBtn").css('display', 'none');
                            }                 
                        break;
    
                        case "google_ads":
                            if(!resources.read){
                                window.$("#googleadsBtn").css('display', 'none');
                            }                
                        break;
    
                        case "facebook":
                            if(!resources.read){
                                window.$("#facebookBtn").css('display', 'none');
                            }                   
                        break;
    
                        case "active":
                            if(!resources.read){
                                window.$("#activeBtn").css('display', 'none');
                            }                  
                        break;
    
                        default:
                            return true;
                        break;    
                    }            
                }            
            }
        }
    }, []);

    return (
        <nav className="navbar navbar-dark bg-dark fixed-top" id="mainNavbar">
            <div className="container-fluid">
                <Link to="/home" className="navbar-brand"><img src={Logo} id="logoNavbar" /></Link>
                <div className="themeChange ms-auto">
                    <button className="btn btn-sm changeThemeBtn" type="button" id="darkModeBtn" title="Apagar as luzes"><span className="material-symbols-outlined">dark_mode</span></button>
                    <button className="btn btn-sm changeThemeBtn" type="button" id="lightModeBtn" title="Acender as luzes"><span className="material-symbols-outlined">light_mode</span></button>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="offcanvas offcanvas-end text-bg-dark" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">Menu</h5>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-start flex-grow-1 pe-3">                        
                            <MenuButton text="Home" route="/home" icon="home" id="homeBtn" />
                            <MenuButton text="Usuários" route="/usuarios" icon="group" id="usuariosBtn" />
                            <MenuButton text="Permissões" route="/permissions" icon="admin_panel_settings" id="permissoesBtn" />
                            <MenuButton text="Verticais" route="/verticais" icon="vertical_align_top" id="verticaisBtn" />
                            <MenuButton text="Key Values" route="/key-values" icon="key" id="keyvaluesBtn" />
                            <MenuButton text="UTM Sources" route="/utm-sources" icon="swap_horiz" id="utmSourcesBtn" />
                            <MenuButton text="Google Ads" route="/google-ads" icon="ads_click" id="googleadsBtn" />
                            {/* <MenuButton text="GAM" route="/gam" icon="label" /> */}
                            <MenuButton text="Facebook" route="/facebook" icon="team_dashboard" id="facebookBtn" />
                            <MenuButton text="Active campaign" route="/active" icon="send" id="activeBtn" />
                            {/* <Dropdown title="Menu dropdown" itens={dropdownItens} /> */}
                            <MenuButton text="Relatórios" route="/relatorios" icon="bar_chart" id="relatoriosBtn" />
                            <MenuButton text="Minha conta" route="/account" icon="manage_accounts" id="minhaContaBtn" />
                        </ul>
                        <div id='loggedSession'>
                            <strong>Olá,&nbsp;{userName}</strong>
                            <br />
                            <a onClick={logout} className='transition'><span className="material-symbols-outlined">logout</span>&nbsp;Logout</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;