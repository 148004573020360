import React, {useState, useEffect} from "react";
import parseHtml from 'html-react-parser';
import apiUrl from '../globals';

const ModalForm = ({action, elemId, title, input, isEdit}) => {

    const [isValid, setIsValid] = useState(true);
    const [textBtn, setTextBtn] = useState(parseHtml('<span class="material-symbols-outlined">save</span>&nbsp;Salvar'));
    const [titleText, setTitleText] = useState("Cadastrar "+title);

    const validateFrom = async (evt) => {

        let dataArray = [];

        let isValidForm = true;
        //console.log(evt);

        for (let i = 0; i < evt.target.length; i++) {

            let attrName = evt.target[i].attributes[0].name;
            let inputValue = evt.target[i].value;
            let inputName = evt.target[i].name;
            let inputType = evt.target[i].type;
            let isRequiredAttr = evt.target[i].attributes.required;
            let isChecked = false;

            if(typeof evt.target[i].checked != "undefined"){
                isChecked = evt.target[i].checked;
            }

            //if(attrName.indexOf('required') !== -1){
            if(typeof isRequiredAttr !== "undefined" && isRequiredAttr !== "undefined"){    
                if(inputValue == "" || inputValue == 0 || inputValue == "0"){
                    setIsValid(false);    
                    isValidForm = false;
                }else{
                    if(inputType != "button" && inputType != "submit" && inputType != "checkbox" && inputType != "radio"){
                        dataArray.push({"name": inputName, "value": inputValue});
                    }else if(inputType == "checkbox" || inputType == "radio"){
                        if(isChecked){
                            dataArray.push({"name": inputName, "value": inputValue});
                            console.log("name: "+inputName+" - isChecked: "+isChecked);
                        }
                    }                  
                }                
            }else{                
                if(inputType != "button" && inputType != "submit" && inputType != "checkbox" && inputType != "radio"){
                    dataArray.push({"name": inputName, "value": inputValue});
                }else if(inputType == "checkbox" || inputType == "radio"){                   
                    if(isChecked){
                        dataArray.push({"name": inputName, "value": inputValue});
                        console.log("name: "+inputName+" - isChecked: "+isChecked);
                    }
                }  
            }
        }

        //if(isValid){
        if(isValidForm){   
            //console.log("dataArray") 
            //console.log(dataArray) 
            await submitForm(evt, dataArray);
        }else{
            evt.preventDefault();
        }
        
    }

    const submitForm = async (evt, dataArray) =>{
        //console.log('dataArray');
        //console.log(JSON.stringify(dataArray));
        evt.preventDefault();
        
        try {
            const response = await fetch(apiUrl+'/'+action, {
                                        method: "POST",
                                        body: JSON.stringify(dataArray),
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Accept": "*/*",
                                            //"token" : apiToken
                                        } 
                                    });                   
            const json = await response.json();

            let successMsg = 'Cadastro realizado com sucesso.';

            if(isEdit){
                successMsg = 'Registro atualizado com sucesso';
            }

            if(json == 1){
                window.$('.modal').modal('hide');
                window.$('.toast').find('.toast-body').html(successMsg);
                window.$('.toast').toast('show');
                window.$('.dataTables').DataTable().ajax.reload();
            }else if(json == 0){
                window.$('.modal').modal('hide');
                window.$('.toast').find('.toast-body').html('Erro ao efetuar cadastro, tente novamente mais tarde.');
                window.$('.toast').toast('show');
            }else if(json == 2){
                window.$('.modal').modal('hide');
                window.$('.toast').find('.toast-body').html('Já existe um cadastro com os mesmos dados, verifique e tente novamente');
                window.$('.toast').toast('show');
            }
            
            if(action == "save-vertical-account"){
                window.$("#id_account").remove();
                window.$("#loadingAccountByPlatform").find('option').eq(0).prop('selected', true);
            }

        }catch(error){
            console.error(error);
        }finally{
         
        }

    }

    useEffect(() => {
        if(isEdit){
            setTextBtn(parseHtml('<span class="material-symbols-outlined">save</span>&nbsp;Atualizar'));
            setTitleText("Atualizar "+title);
        }
    }, []);

    return (
        <div>
            <div className="modal modal-lg fade" id={elemId} tabIndex="-1" aria-hidden="true">
                <form onSubmit={validateFrom}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5">{titleText}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Fechar"></button>
                        </div>
                        <div className="modal-body">
                            {(() => {
                                let inputsArray = [];
                                for(let i = 0; i < input.length; i++){

                                    let html = '';
                                    let requiredAttr = '';

                                    if(input[i].isRequired){
                                        requiredAttr = 'required';
                                    }

                                    if(input[i].type == 'select'){

                                        html += '<div class="mb-3" key="'+i+'">'+
                                                    '<label for="'+input[i].name+'" class="form-label">'+input[i].label+'</label>'+
                                                    '<select id="'+input[i].id+'" '+requiredAttr+' class="selectInput form-control" name="'+input[i].name+'" defaultValue="'+input[i].value+'">';

                                        for(let k = 0; k < input[i].options.length; k++){
                                            html += '<option value="'+input[i].options[k].value+'">'+input[i].options[k].text+'</option>';                                   
                                        }

                                        html += '</select>';

                                    }else if(input[i].type == 'textarea'){

                                        html += '<div class="mb-3" key="'+i+'">'+
                                                    '<label for="'+input[i].name+'" class="form-label">'+input[i].label+'</label>'+
                                                    '<textarea '+requiredAttr+' class="texareaInput form-control" name="'+input[i].name+'"></textarea>';

                                    }else if(input[i].type == 'radio' || input[i].type == 'checkbox'){

                                        html += '<div class="form-label" key="'+i+'">';
                                        html += '<label for="'+input[i].name+'" class="form-check-label">'+input[i].label+'</label><br />';

                                        for(let j = 0; j < input[i].options.length; j++){
                                            if(input[i].options[j].checked){
                                                html += '<span class="checkRadioContainer"><input checked type="'+input[i].type+'" class="checkRadioInput form-check-input" value="'+input[i].options[j].value+'" name="'+input[i].name+'[]">&nbsp;'+input[i].options[j].text+'</input></span>&nbsp;&nbsp;';
                                            }else{
                                                html += '<span class="checkRadioContainer"><input type="'+input[i].type+'" class="checkRadioInput form-check-input" value="'+input[i].options[j].value+'" name="'+input[i].name+'[]">&nbsp;'+input[i].options[j].text+'</input></span>&nbsp;&nbsp;';                                     
                                            }
                                        }

                                        html +=  "<br /><hr />";

                                    }else{

                                        if(input[i].type == "password" && isEdit){
                                            html += '<div class="mb-3" key="'+i+'">'+
                                                    '<label for="'+input[i].name+'" class="form-label">'+input[i].label+'</label>'+
                                                    '<input type="'+input[i].type+'" class="normalInput form-control" name="'+input[i].name+'" />';
                                        }else{
                                            html += '<div class="mb-3" key="'+i+'">'+
                                                    '<label for="'+input[i].name+'" class="form-label">'+input[i].label+'</label>'+
                                                    '<input '+requiredAttr+' type="'+input[i].type+'" class="normalInput form-control" name="'+input[i].name+'" />';
                                        }
                                    }

                                    html += '</div>';

                                    html = parseHtml(html);
                                    inputsArray.push(html);
                                }

                                return (inputsArray);
                            
                            })()}  
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-light" data-bs-dismiss="modal">Fechar</button>
                            <button type="submit" className="btn btn-outline-success">{textBtn}</button>
                        </div>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    );
}

export default ModalForm;